import React from 'react';
import { Box, Heading, Image } from '@chakra-ui/core';

import Container from '../container';
import Grid from '../grid';
import WYSIQYG from '../WYSIWYG';

const ImageSection = ({ image, heading, text, rtl, lastSection }) => {
  return (
    <Container marginBottom={lastSection ? 0 : [24, 40]}>
      <Grid alignItems="center">
        <Box
          gridColumn={['1 / -1', rtl ? '7 / -1' : '1 / 7']}
          gridRow={['1', '1']}
          position="relative"
        >
          <Image
            alt={image.alt}
            src={image.asset.fluid.src}
            srcSet={image.asset.fluid.srcSet}
            sizes={image.asset.fluid.sizes}
            borderRadius="16px"
          />
        </Box>
        <Box
          gridColumn={['1 / -1', rtl ? '1 / 7' : '7 / -1']}
          gridRow={['2', '1']}
          paddingX={[1, 0]}
        >
          {heading ? <Heading marginBottom={[4, 8]}>{heading}</Heading> : null}
          {text ? <WYSIQYG text={text} /> : null}
        </Box>
      </Grid>
    </Container>
  );
};

export default ImageSection;
