import React from 'react';
import styled from '@emotion/styled';
import BlockContent from '@sanity/block-content-to-react';
import { Box } from '@chakra-ui/core';

const Content = styled(Box)`
  p + p {
    margin-top: 1rem;
  }

  a {
    text-decoration: underline;
  }

  ul,
  ol {
    list-style-position: inside;
    padding-left: 1rem;
  }
`;

const WYSIQYG = ({ text, ...props }) => (
  <Content {...props}>
    <BlockContent blocks={text} />
  </Content>
);

export default WYSIQYG;
