import React from 'react';
import { Stack, Heading, Image } from '@chakra-ui/core';

import Container from '../container';
import Grid from '../grid';
import WYSIQYG from '../WYSIWYG';

const ProductSection = ({ lastSection, heading, text, products }) => {
  return products.length ? (
    <Container marginBottom={lastSection ? 0 : [24, 40]}>
      {heading ? <Heading marginBottom={[4, 8]}>{heading}</Heading> : null}
      {text ? <WYSIQYG text={text} marginBottom={[16]} /> : null}
      <Grid alignItems="flex-start" gridRowGap="16">
        {products.map(product => (
          <Stack
            key={product.heading}
            gridColumn={['span 12', `span ${Math.floor(12 / products.length)}`]}
            spacing="8"
          >
            <Heading as="h3" size="lg">
              {product.heading}
            </Heading>
            <Image
              alt={product.image.alt}
              src={product.image.asset.fluid.src}
              srcSet={product.image.asset.fluid.srcSet}
              sizes={product.image.asset.fluid.sizes}
            />
            {product.text ? <WYSIQYG text={product.text} /> : null}
          </Stack>
        ))}
      </Grid>
    </Container>
  ) : null;
};

export default ProductSection;
