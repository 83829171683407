import {compose, join, over, lensIndex, toUpper} from 'ramda'

export function ucfirst (str) {
  return `${str.substr(0, 1).toUpperCase()}${str.substr(1)}`
}

export const upperFirst = compose(
  join(''),
  over(lensIndex(0), toUpper)
)
