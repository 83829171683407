import React from 'react';

import { upperFirst } from '../lib/string-utils';
import * as SectionComponents from './sections';

function resolveSections(section) {
  const Section = SectionComponents[upperFirst(section._type)];

  if (Section) {
    return Section;
  }

  console.error('Cant find section', section); // eslint-disable-line no-console
  return null;
}

const RenderSections = ({ sections }) => {
  if (!sections) {
    console.error('Missing section');
    return <div>Missing sections</div>;
  }

  return (
    <>
      {sections.map((section, index) => {
        const SectionComponent = resolveSections(section);
        if (!SectionComponent) {
          return <div>Missing section {section._type}</div>;
        }
        return (
          <SectionComponent
            {...section}
            key={section._key}
            lastSection={index === sections.length - 1}
          />
        );
      })}
    </>
  );
};

export default RenderSections;
