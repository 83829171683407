import React from 'react';
import { Box, Heading } from '@chakra-ui/core';
import BlockContent from '@sanity/block-content-to-react';
import ImageGallery from 'react-image-gallery';

import Container from '../container';
import Grid from '../grid';

const GallerySection = ({ images, heading, text, lastSection }) => {
  //           srcSet={image.asset.fluid.srcSet}
  //           sizes={image.asset.fluid.sizes}

  const galleryImages = images.map(image => ({
    original: image.asset.fluid.src,
    originalAlt: image.alt,
    thumbnail: image.asset.thumbnail.src,
    srcSet: image.asset.fluid.srcSet,
    sizes: image.asset.fluid.sizes,
  }));

  return (
    <Container marginTop={[24, 48]} marginBottom={lastSection ? 0 : [24, 40]}>
      <Grid>
        <Box gridColumn={['1/-1', '2/-2']}>
          {heading ? <Heading marginBottom={[4, 8]}>{heading}</Heading> : null}
          {text ? <BlockContent blocks={text} /> : null}
          <ImageGallery items={galleryImages} lazyLoad showPlayButton={false} />
        </Box>
      </Grid>
    </Container>
  );
};

export default GallerySection;
