import React from 'react';
import { Box, Heading, Stack } from '@chakra-ui/core';
import BlockContent from '@sanity/block-content-to-react';
import styled from '@emotion/styled';

import Container from '../container';
import Grid from '../grid';
import ResponsiveBackground from '../responsiveBackground';

const Taglines = styled(Box)`
  & > * {
    color: white;
    text-shadow: 1px 1px 2px black;
  }
`;

const Hero = ({ backgroundImage, heading, tagline, lastSection }) => {
  return (
    <Box marginBottom={lastSection ? 8 : [16, 32]}>
      <ResponsiveBackground
        src={backgroundImage.asset.fluid.src}
        srcSet={backgroundImage.asset.fluid.srcSet}
        sizes={backgroundImage.asset.fluid.sizes}
      >
        <Container
          paddingTop={['17rem', '320px']}
          paddingBottom={['24', '32', '48']}
        >
          <Grid>
            <Stack
              gridColumn={['1 / -1', '1 / -1', '1 / 10']}
              spacing={['3', '6']}
              paddingX={[1, 0]}
            >
              {heading ? (
                <Heading as="h1" color="white" textShadow="1px 1px 2px black">
                  {heading}
                </Heading>
              ) : null}
              {tagline ? (
                <Taglines>
                  <BlockContent blocks={tagline} />
                </Taglines>
              ) : null}
            </Stack>
          </Grid>
        </Container>
      </ResponsiveBackground>
    </Box>
  );
};

export default Hero;
