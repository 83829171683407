import React, { useEffect, useState, useRef } from 'react';
import { Box, Image } from '@chakra-ui/core';

const ResponsiveBackground = ({ children, src, srcSet, sizes, ...props }) => {
  const [backgroundUrl, _setBackgroundUrl] = useState(src);
  const backgroundUrlRef = useRef(backgroundUrl);
  const imageRef = useRef(null);

  const setBackgroundUrl = data => {
    backgroundUrlRef.current = data;
    _setBackgroundUrl(data);
  };

  const handleLoad = () => {
    if (imageRef && imageRef.current && imageRef.current.currentSrc) {
      setBackgroundUrl(imageRef.current.currentSrc);
    }
  };

  const handleResize = () => {
    if (imageRef.current.currentSrc !== backgroundUrlRef.current) {
      setBackgroundUrl(imageRef.current.currentSrc);
    }
  };

  useEffect(() => {
    const isClient = typeof window === 'object';
    if (!isClient) {
      return false;
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Box
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
      backgroundSize="cover"
      backgroundImage={`url(${backgroundUrl})`}
      {...props}
    >
      <Image
        ref={imageRef}
        {...{ src, srcSet, sizes }}
        onLoad={handleLoad}
        display="none"
      />
      {children}
    </Box>
  );
};

export default ResponsiveBackground;
