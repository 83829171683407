import React from 'react';
import { Box, Heading } from '@chakra-ui/core';
import styled from '@emotion/styled';

import Container from '../container';

const VideoContainer = styled(Box)`
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
`;

const IFrame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const YoutubeSection = ({ heading, videoID, lastSection }) => (
  <Container>
    <Box marginBottom={lastSection ? 0 : [24, 32]}>
      {heading ? <Heading marginBottom={[4, 8]}>{heading}</Heading> : null}
      {videoID ? (
        <VideoContainer>
          <IFrame
            src={`https://www.youtube-nocookie.com/embed/${videoID}`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullscreen
          ></IFrame>
        </VideoContainer>
      ) : null}
    </Box>
  </Container>
);

export default YoutubeSection;
