import React from 'react';
import styled from '@emotion/styled';
import { Box, Heading } from '@chakra-ui/core';

import Container from '../container';
import Grid from '../grid';
import WYSIWYG from '../WYSIWYG';

const Map = styled(Box)`
  overflow: hidden;
  padding-bottom: 65%;
  position: relative;
  height: 0;
`;

const Iframe = styled.iframe`
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
`;

const ContactSection = ({ lastSection, heading, text, address }) => {
  return (
    <Container marginBottom={lastSection ? 0 : [24, 40]}>
      {heading ? (
        <Heading marginBottom={text ? [4, 8] : [12]}>{heading}</Heading>
      ) : null}
      {text ? <WYSIWYG text={text} marginBottom={[12]} /> : null}
      <Grid alignItems="center" gridRowGap="16">
        <Box textAlign="center" gridColumn={['1 / -1', '1/7']}>
          {address ? <WYSIWYG text={address} /> : null}
        </Box>
        <Box gridColumn={['1 / -1', '7/-1']}>
          <Map>
            <Iframe
              width="600"
              height="450"
              frameBorder="0"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d303223.9916332515!2d8.6029088!3d53.5767332!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b14f5d712cdbd5%3A0x4861438dc3fa51b9!2sAngushof%20Hubert!5e0!3m2!1sde!2sde!4v1592576561452!5m2!1sde!2sde"
              allowFullScreen
            />
          </Map>
        </Box>
      </Grid>
    </Container>
  );
};

export default ContactSection;
