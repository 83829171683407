import React from 'react';
import { Box, Heading } from '@chakra-ui/core';

import Container from '../container';
import WYSIQYG from '../WYSIWYG';

const ImageSection = ({ centered, heading, text, lastSection }) => (
  <Container>
    <Box
      marginBottom={lastSection ? 0 : [24, 40]}
      padding={
        centered
          ? ['0 0.25rem', '0 10%', '0 18%']
          : ['0 0.25rem', '0 10% 0 0', '0 18% 0 0']
      }
      textAlign={centered ? 'center' : 'left'}
    >
      {heading ? <Heading marginBottom={[4, 8]}>{heading}</Heading> : null}
      {text ? <WYSIQYG text={text} /> : null}
    </Box>
  </Container>
);

export default ImageSection;
